import React, { Component } from 'react';

class Hjalpa extends Component {

  render () {

  	return (
  		<center>
<div className="info kompakt" style={{textAlign: "left"}}>
<center><h1>Besökare</h1></center>
<div>
<center><h2>Fredagskvällar</h2></center>
<p>Folkmusikkaféet är en ideell förening. Vi verkar för att skapa en mötesplats för levande folkmusik och dans i Göteborg.</p>
<table>
<tr>
<td style={{"padding-right": "20px"}}><b>Konsert- och danskvällar:</b></td><td>Konserten börjar 20:00, därefter dans fram till klockan 23:00. Serveringen stänger 22:30.</td>
      </tr>
<tr>
  <td><b>Stordanskvällar</b></td>
  <td>Musikerna spelar till dans från 20:00 till 23:00 (dans hela kvällen, ingen
  konsert).</td>
      </tr>
      <tr>
        <td><b>Heldanskvällar</b></td>
        <td>Vi bokar tre lokala grupper som turas om att spela till dans från 20:00 till
        23:00.</td>
      </tr>
      <tr>
        <td><b>Spel- och danskvällar</b></td>
        <td>Dans till spellista. 1-3 grupper bokas för att inleda kvällen, därefter är
det fritt fram att skriva upp sig på listan.</td>
      </tr>
<tr>
      <td><b>Stugor:</b></td><td>
        Ta chansen att lära dig en låt, en visa eller en dans, på en spel-, vis- eller dansstuga. I programmet står det när de hålls.</td>
</tr>
<tr>
<td>
<b>Kurser:</b></td><td>Spelmanslaget pågår hela hösten. Mer information här: <a href="kurser">kurser</a></td>
</tr>
</table>

<center><h2>Servering</h2></center>

I vårt cafée serveras smörgåsar med olika pålägg, fikabröd, te, kaffe och alkoholfri öl.
Det finns veganska, vegetariska samt ordinarie pålägg.

<center><h2>Medlemskap</h2></center>

<p>

Som medlem ger du ett viktigt stöd till föreningen så att folkkulturen kan fortsätta utvecklas och frodas.
Du får rabatt på din entré samt 10% rabatt på <a href="https://dansskor.se">dansskor.se</a>. Uppge fmk10 i kassan!
<br/><br/>

<center><strong>Bli medlem:</strong><br/></center>
<ul>
  <li>Betala i entrén på en konsertkväll</li>
  <li>Sätt in 50 kr på PG 107741-1 eller Swish 1236279434 och ange namn, adress, telefonnummer samt e-postadress.</li>
</ul>
<br/> 
Medlemskap gäller per kalenderår. Våra medlemsregler finns att läsa här: <a href="https://folkmusikkafeet.se/medlemsregler.pdf">medlemsregister</a>.
</p>

<center>
  <h2 style={{"display": "inline"}} id="entre-ungdom">Entré Ungdom</h2><br/>
  <small><i>Ungdomssatsning Folkmusikkaféet</i></small>
</center>
  <br/>
  Är du under 26 år gammal? I så fall så har vi ett väldigt bra erbjudande för dig.<br/>
  Ungdomar upp till 25 får nämligen gratis inträde en gång per kalenderår när de köper medlemskort.
  <br/>
<center><h2>Entré</h2></center>

<p>
 Medlemskap är <b>ej</b> obligatoriskt (sen våren 2020). Medlemskap kostar 50 kr per kalenderår.<br/>
 Inträde betalas på plats. Via Swish, med kort eller kontant.
</p>

<p>
 Inträdet på konsert- och danskvällar och stordanskvällar varierar då det finns två upplägg, antingen 160 kr eller 180 kr i regel; se respektive konsertkväll i programmet.<br/>
 Vi har även ett särskilt erbjudande för ungdomar, folk under 26 år, som vi skrev om ovanför.
 Generellt ser vår prisbild ut på två sätt, antingen 160 kr eller 180 kr, mer precist så ser det ut såhär:
</p>

<center>
<h2>Kvällar med 160 kr entré</h2>
<table style={{borderSpacing: "10px"}} className="rundad">
<thead>
<tr><th></th><th>Ordinarie</th><th>Medlem</th><th>Medlemskap (år)</th></tr>
</thead>
<tbody>
<tr><td>Vuxen</td><td>160 kr</td><td>130 kr</td><td>50 kr</td></tr>
<tr><td>RFoD-medlem</td><td>140 kr</td><td>110 kr</td><td>50 kr</td></tr>
<tr><td>Ungdom/Student</td><td>85 kr</td><td>65 kr</td><td>50 kr</td></tr>
<tr><td>Barn/medhjälpare</td><td>Gratis</td><td>Gratis</td><td></td></tr>
</tbody>
</table>
</center>

<center>
<h2>Kvällar med 180 kr entré</h2>
<table style={{borderSpacing: "10px"}} className="rundad">
<thead>
<tr><th></th><th>Ordinarie</th><th>Medlem</th><th>Medlemskap (år)</th></tr>
</thead>
<tbody>
<tr><td>Vuxen</td><td>180 kr</td><td>150 kr</td><td>50 kr</td></tr>
<tr><td>RFoD-medlem</td><td>160 kr</td><td>130 kr</td><td>50 kr</td></tr>
<tr><td>Ungdom/Student</td><td>95 kr</td><td>75 kr</td><td>50 kr</td></tr>
<tr><td>Barn/medhjälpare</td><td>Gratis</td><td>Gratis</td><td></td></tr>
</tbody>
</table>
</center>

<br/>
<br/>
<center>För ytterligare information se <a href="medlemsregler.pdf">medlemsreglerna</a></center>.
<br/>

<center><h2>Tillgänglighet</h2></center>

<p>
 Huvudentrén har trappor. Om du behöver finns också en hiss som går från ungdomsgården. Be någon påkalla entrévärdens uppmärksamhet så att de kan låsa upp, och gå sedan runt hörnet och in på gården. Det är skyltat fram till dörren och innanför dörren är det skyltat till hissen.
</p>
<p>
 Har du några frågor? Kontakta <a href="mailto:folkmusikkafeet@folkmusikkafeet.net"><img src="img/ikoner/epost-12.png" className="email" alt="e-post:" /> folkmusikkafeet@folkmusikkafeet.net</a></p>

</div></div></center>
);
}

}


export default Hjalpa;
